import React from 'react'
import {Row, Col, Container, Collapse, Card, CardHeader, CardBody} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle,
  Header2WithLeftBoarderGift, HeaderForSubtitleWithLeftBorderGift} from '../text_style'

import { StyledContainer } from '../layout'

import { AnimatedText, SectionIntroHeader } from './castie_common_component'

import {
  MainText1,
  MainText1Bold,
  HoverAnimatedMainText1,
  Header4WithLeftBorder,
} from './castie_style'

import TrackVisibility from 'react-on-screen';
import CompetitiveAnalysis from './competitive_analysis_accodion_castie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LiteratureReview from './literature_review_accordian_castie'


let CardHeaderWithHover = styled(CardHeader)`
//  Change it later
`


class ResearchActivities extends React.Component {

  constructor(props){
    super(props)
    this.state={firstCollapseOpen: true, secondCollapseOpen: true, thirdCollapseOpen: false}
  }

  toggleFirst = ()=>{
    this.setState({firstCollapseOpen: !this.state.firstCollapseOpen,

    })
  }
  toggleSecond = ()=> {
    this.setState({ secondCollapseOpen: !this.state.secondCollapseOpen })
  }

  toggleThird = ()=> {
    this.setState({ thirdCollapseOpen: !this.state.thirdCollapseOpen })
  }

  render(){

    return (
      <StyledContainer  fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>

          <TrackVisibility>
            <AnimatedText text={"To understand users and podcast industry, we conducted: "}/>
          </TrackVisibility>

          <MainText1>
            <ol  style={{paddingLeft: `25px`}}>
              <li>
                <MainText1>
                <MainText1Bold> Literature review </MainText1Bold>
                  to have a holistic view on the industry and users</MainText1>
              </li>

              <li>
                <MainText1Bold> Competitive analysis </MainText1Bold>
                  to learn from the design of other applications
              </li>

            </ol>

          </MainText1>
          </Col>

        </Row>

        {getSeperatorWithHeight(25)}
        <Row>
          {getColOf3()}
          <Col md={6}>
          <div id="accordion">
            <Card style={{borderWidth:0}}>

              {/*Card One*/}
              <CardHeaderWithHover onClick={this.toggleFirst} style={{borderWidth:0,
                 padding: "0 0 0 0" ,backgroundColor: "transparent"}} id="headingOne">

                <Header4WithLeftBorder className="hvr-fade-accordion castie_hover"> Literature interview
                  <FontAwesomeIcon style={{position: "absolute", right: "20px"}}  size="lg"
                                   icon={ this.state.firstCollapseOpen? 'angle-up' : 'angle-down'}/>
                </Header4WithLeftBorder>

              </CardHeaderWithHover>

              <Collapse isOpen={this.state.firstCollapseOpen}>
                <CardBody >
                  <LiteratureReview/>
                </CardBody>
              </Collapse>

              {/*Card Second*/}
              <CardHeader onClick={this.toggleSecond} style={{borderWidth:0,
                backgroundColor:"transparent", padding: "0 0 0 0", marginTop: "24px"}} id="headingOne">

                <Header4WithLeftBorder className="hvr-fade-accordion castie_hover"> Competitive Analysis
                  <FontAwesomeIcon style={{position: "absolute", right: "20px"}}  size="lg"
                                   icon={ this.state.secondCollapseOpen? 'angle-up' : 'angle-down'}/>
                </Header4WithLeftBorder>

              </CardHeader>

              <Collapse isOpen={this.state.secondCollapseOpen}>
                <CardBody >
                  <CompetitiveAnalysis/>
                </CardBody>
              </Collapse>

              {/*/!*Card Third*!/*/}
              {/*<CardHeader onClick={this.toggleThird} style={{borderWidth:0,*/}
                {/*backgroundColor:"transparent", padding: "0 0 0 0", marginTop: "24px"}} id="headingOne">*/}

                {/*<Header4WithLeftBorder className="hvr-fade-accordion"> Competitive Analysis*/}
                  {/*<FontAwesomeIcon style={{position: "absolute", right: "20px"}}  size="lg"*/}
                                   {/*icon={ this.state.thirdCollapseOpen? 'angle-up' : 'angle-down'}/>*/}
                {/*</Header4WithLeftBorder>*/}

              {/*</CardHeader>*/}

              {/*<Collapse isOpen={this.state.thirdCollapseOpen}>*/}
                {/*<CardBody >*/}
                  {/*<CompetitiveAnalysis/>*/}
                {/*</CardBody>*/}
              {/*</Collapse>*/}

            </Card>
          </div>
          </Col>
        </Row>

      </StyledContainer>
    )
  }

}

export default ResearchActivities