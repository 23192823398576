import React from 'react'
import {Row, Col} from 'reactstrap'

import styled from 'styled-components'
import highlightImage from '../../images/castie/highlight-2.png'
import videoPrototypeImage from '../../images/castie/video_prototype.png'

import { StyledContainer } from '../layout'
import {
  Title,
  Acknowledgement,
  Brief,
  mainTextColor,
  accentColor,
  textFont,
  IntroLine,
  IntroSubHeader,
  Header4WithLeftBorder,
  MainText1, MainText1Bold,
} from './castie_style'
import { getColOf3, getSeperatorWithHeight } from '../text_style'
import { AnimatedText, ZoomableImageWithModal } from './castie_common_component'
import TrackVisibility from 'react-on-screen';
import {textFontWeight} from './castie_style'

class DesignDirection extends React.Component {

  render(){
    return(
    <StyledContainer fluid={true}>

      <Row>
        {getColOf3()}
        <Col md={6}>
          <TrackVisibility>
            <AnimatedText text={"After analyzing all ideas, we decided on our design directions"}/>
          </TrackVisibility>

          {getSeperatorWithHeight(8)}
          <Header4WithLeftBorder>
            Design Directions
          </Header4WithLeftBorder>


          {getSeperatorWithHeight(24)}

          <MainText1>
          <MainText1Bold> Configure default setting: </MainText1Bold>
          </MainText1>

          <MainText1 style={{paddingLeft:`16px`}}>
              if we want Castie to interrupt users less and keep the listening experience more fluid, we need to make the command shorter and provide a default for users.
          </MainText1>

          {getSeperatorWithHeight(24)}

          <MainText1>
            <MainText1Bold> Combine screens with VOI: </MainText1Bold>
          </MainText1>

          <MainText1 style={{paddingLeft:`16px`}}>
              Using voice interaction for precise controlling and browsing numerous items can be inconvenient. Thus, we want to use screens for those tasks

          </MainText1>

        </Col>
      </Row>

    </StyledContainer>
    )
  }

}

export default DesignDirection