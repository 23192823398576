import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import {getSeperatorWithHeight} from '../text_style'
import podcastImage from '../../images/castie/podcast_icon.jpg'
import { StyledContainer } from '../layout'
import { SectionIntroHeader } from './castie_common_component'
import {MainText1} from './castie_style'


class ProblemStatement extends React.Component{

  render(){

    let data = {
      header: "Problem Statement",
      subtitle: "Introduce our user problem space"
    }

    return(

     <StyledContainer fluid={true}>

       <Row>
          <Col md={3}/>
          <Col md={6}>
            <SectionIntroHeader header={data.header} subtitle={data.subtitle} />
          </Col>
       </Row>

       {getSeperatorWithHeight(30)}

       <Row>
         <Col md={3}/>
         <Col md={4}>
          <MainText1>
            The Podcast has become a popular channel for individuals and organizations to share their voice through
            radio. Despite the high popularity of podcasts, many podcast applications still limit
            listeners’ ability to customize their listening experience. The podcast applications do not allow
            users to keep their favourite quotes or add comments when they listen to podcasts. Thus, we designed a
            voice interaction assistant for our target users. It customizes listeners experience by allowing them to
            highlight key takeaways from the podcast.
          </MainText1>
         </Col>

        <Col md={2}>
          <img src={podcastImage} className="img-fluid" style={{paddingLeft:`10px`}} alt="gift"/>
        </Col>
       </Row>


     </StyledContainer>

    )
  }
}

export default ProblemStatement