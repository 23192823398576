import React from 'react'
import {Row, Col, Container, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'

import {
  Header2SubtitleWithLeftBorder,
  Header2WithLeftBoarder,
  HoverAnimatedMainText1,
} from './castie_style'
import {ZoomableImage} from './castie_style'

export class SectionIntroHeader extends React.Component{

  constructor( props ){
    super(props)
    this.state = {
      header: props.header,
      subtitle: props.subtitle
    }
  }

  render(){
    return(
      <div>
        <Header2WithLeftBoarder> {this.props.header}</Header2WithLeftBoarder>
        <Header2SubtitleWithLeftBorder> {this.props.subtitle} </Header2SubtitleWithLeftBorder>
      </div>
    )
  }
}

export const AnimatedText = ({ isVisible, text }) => {
  const backgroundPosition= isVisible ? '-99.99% 0;' : '-0% 0;'
  console.log(backgroundPosition)

  const highlightClass = isVisible? "highlight" : ""

  return (
    <HoverAnimatedMainText1 className={highlightClass} style={{
      marginBottom: `11px`
    }}> {text} </HoverAnimatedMainText1>
  );
}


// export class SectionSeperator extends React.Component{

//   render () {
//     return (
//       <Row>
//         <Col md={4}/>
//         <Col md={4} style={{textAlign:"center"}} >
//           <img src={sectionSeperator} alt={"section seperator"} />
//         </Col>
//       </Row>
//     )
//   }
// }

export class SectionSeperator extends React.Component{

  render () {
    return (
      <Row>
        <Col md={4}/>
        <Col md={4} style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}} >
          {/* <img src={sectionSeperator} alt={"section seperator"} /> */}
          <div style={{height: "1rem", width: "1rem", borderRadius:"0.5rem", borderWidth: 1, borderStyle:"solid", borderColor:"#D25709", marginLeft: "1.875rem"}} />
          <div style={{height: "1rem", width: "1rem", borderRadius:"0.5rem", borderWidth: 1, borderColor:"#D25709",borderStyle:"solid", marginLeft: "1.875rem"}} />
          <div style={{height: "1rem", width: "1rem", borderRadius:"0.5rem", borderWidth: 1, borderColor:"#D25709", borderStyle:"solid",marginLeft: "1.875rem"}} />
        </Col>
      </Row>
    )
  }
}

export class ZoomableImageWithModal extends React.Component{

  constructor(props){
    super(props)
    this.state = {modalOpen: false};

  }

  toggle = () => {
    this.setState({modalOpen: !this.state.modalOpen})
  }

  render(){

    return(
      <Container>
        <ZoomableImage style={{paddingRight: `0px`}} src={this.props.image} alt={this.props.header} className={"img-fluid"} onClick={this.toggle}/>
        <Modal isOpen={this.state.modalOpen} size='xl'>
          <ModalHeader toggle={this.toggle}>{this.props.header} </ModalHeader>
          <ModalBody>
            <Container className="d-flex justify-content-center">
              <img src={this.props.image} className={"img-fluid"}/>
            </Container>
          </ModalBody>

        </Modal>
      </Container>

    )
  }
}