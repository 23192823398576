import React from 'react'

import {MainText1, MainText1Bold, HoverAnimatedMainText1, Header4WithLeftBorder, AccordianMainText1} from './castie_style'

import { getColOf3, getSeperatorWithHeight } from '../text_style'
import caImage from '../../images/hci_foundation/Competitive_analysis.svg'

class LiteratureReview extends React.Component{

  render(){


    return(
      <div>
        <AccordianMainText1> By reading different reports and articles online, we found:  </AccordianMainText1>
        <AccordianMainText1>
          <ul>
            <li style={{marginBottom: 0}}> in the U.S, one-third of Americans age 25 to 54 listen to podcasts monthly in U.S </li>
            <li style={{marginBottom: 0}}> within the last couple years (2013 - 2017), the population of active listeners on podcast has doubled </li>
            <li style={{marginBottom: 0}}> about 50% of the podcast listeners adopt podcasts as an education/learning tool </li>
            <li style={{marginBottom: 0}}> about 37% of the listeners listen to the podcasts on their commute way </li>
          </ul>
        </AccordianMainText1>

        <AccordianMainText1 style={{marginTop: `30px`}}>
          These data suggests that there exists a huge market for podcasts. Besides learning from Podcast has become more and more popular methods.
        </AccordianMainText1>

      </div>
    )
  }
}

export default LiteratureReview