import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import highlightImage from '../../images/castie/highlight-2.png'
import videoPrototypeImage from '../../images/castie/video_prototype.png'

import { StyledContainer } from '../layout'
import {
  Header4WithLeftBorder,
  MainText1, MainText1Bold,
} from './castie_style'
import { getColOf3, getSeperatorWithHeight } from '../text_style'
import { AnimatedText, ZoomableImageWithModal } from './castie_common_component'
import TrackVisibility from 'react-on-screen';
import { textFontWeight } from '../site_style'


class VideoPrototyping extends React.Component {

  render(){
    return(

    <StyledContainer fluid={true}>

      <Row>
        {getColOf3()}
        <Col md={6}>
          <Header4WithLeftBorder>
            Video Prototyping
          </Header4WithLeftBorder>

        </Col>

      </Row>
      {getSeperatorWithHeight(24)}
      <Row>
        {getColOf3()}
        <Col md={6}>

          <MainText1>
            Due to the limitation in shooting in the real public transportation, we mimicked the bus environment.
            Then we shooted a video to demo the use case of Castie.
          </MainText1>

          {getSeperatorWithHeight(16)}

          <ol>

            <li>Created a poster of the side of a bus </li>

            <li>Pasted onto a foam board</li>

            <li>Carved the window part out and paste in </li>

            <li>I acted behind form board </li>

            <li>My teammate shoot the video and acted as the voice assistant </li>

          </ol>

        </Col>
      </Row>

      {getSeperatorWithHeight(16)}
      <Row>
        {getColOf3()}
        <Col md={6} style={{textAlign: "center"}} >
          <img src={videoPrototypeImage}   />
        </Col>
      </Row>

    </StyledContainer>
  )
  }
}

export default VideoPrototyping