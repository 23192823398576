import React from 'react'
import {Row, Col, Card, CardBody, CardHeader, Collapse} from 'reactstrap'

import styled from 'styled-components'
import highlightImage from '../../images/castie/highlight-2.png'
import videoPrototypeImage from '../../images/castie/video_prototype.png'

import { StyledContainer } from '../layout'
import {
  Title,
  Acknowledgement,
  Brief,
  mainTextColor,
  accentColor,
  textFont,
  IntroLine,
  IntroSubHeader,
  Header4WithLeftBorder,
  MainText1, MainText1Bold,
} from './castie_style'
import { getColOf3, getSeperatorWithHeight } from '../text_style'
import { AnimatedText, ZoomableImageWithModal } from './castie_common_component'
import TrackVisibility from 'react-on-screen';
import {textFontWeight} from './castie_style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let CardHeaderWithHover = styled(CardHeader)`
//  Change it later
`


class IdeationsCategories extends React.Component {


    constructor(props){
      super(props)
      this.state={firstCollapseOpen: true, secondCollapseOpen: true, thirdCollapseOpen: false}
    }

    toggleFirst = ()=>{
      this.setState({firstCollapseOpen: !this.state.firstCollapseOpen,

      })
    }
    toggleSecond = ()=> {
      this.setState({ secondCollapseOpen: !this.state.secondCollapseOpen })
    }

    render(){

      return(
        <StyledContainer  fluid={true}>
          <Row>
            {getColOf3()}
            <Col md={6}>

              <TrackVisibility>
                <AnimatedText text={"Ideas we generated can be summarized into two categories:  "}/>
              </TrackVisibility>

              <MainText1>
                <ol  style={{paddingLeft: `25px`}}>
                  <li>
                    <MainText1>
                      <MainText1Bold> Default </MainText1Bold>
                      vs <MainText1Bold> Explicit </MainText1Bold>
                    </MainText1>
                  </li>

                  <li>
                    <MainText1Bold> Voice interaction only </MainText1Bold>
                    vs
                    <MainText1Bold> Combination of voice interaction and screens </MainText1Bold>

                  </li>

                </ol>

              </MainText1>
            </Col>
          </Row>

          {getSeperatorWithHeight(25)}
          <Row>
            {getColOf3()}
            <Col md={6}>
              <div id="accordion">
                <Card style={{borderWidth:0}}>

                  {/*Card One*/}
                  <CardHeaderWithHover onClick={this.toggleFirst} style={{borderWidth:0,
                    padding: "0 0 0 0" ,backgroundColor: "transparent"}} id="headingOne">

                    <Header4WithLeftBorder className="hvr-fade-accordion castie_hover"> Default or Explicit
                      <FontAwesomeIcon style={{position: "absolute", right: "20px"}}  size="lg"
                                       icon={ this.state.firstCollapseOpen? 'angle-up' : 'angle-down'}/>
                    </Header4WithLeftBorder>

                  </CardHeaderWithHover>

                  <Collapse isOpen={this.state.firstCollapseOpen}>
                    <CardBody>
                      <MainText1>
                        There were different ways to say one sentence. We discussed how much information
                        we should allow users to give in one sentence, and under what circumstances we
                        would set a default for simple commands so that users can say the commands quickly and keep listening.

                      </MainText1>

                      {getSeperatorWithHeight(12)}

                      <MainText1>
                        E.g:
                          <ul>
                            <li style={{marginBottom:"5px"}}>
                              “Castie, Highlight.”  vs “Castie, Highlight last two sentences.”
                            </li>

                            <li style={{marginBottom:"5px"}}>
                              “Castie, play the highlights” vs “Castie, play the first highlight in this podcast“
                            </li>

                            <li style={{marginBottom:"5px"}}>
                              “Castie, set voice memo. ” vs “Castie, set this voice memo: ********”
                            </li>
                          </ul>

                      </MainText1>


                    </CardBody>
                  </Collapse>

                  {/*Card Second*/}
                  <CardHeader onClick={this.toggleSecond} style={{borderWidth:0,
                    backgroundColor:"transparent", padding: "0 0 0 0", marginTop: "24px"}} id="headingOne">

                    <Header4WithLeftBorder className="hvr-fade-accordion castie_hover"> VOI only or VOI with Screens
                      <FontAwesomeIcon style={{position: "absolute", right: "20px"}}  size="lg"
                                       icon={ this.state.secondCollapseOpen? 'angle-up' : 'angle-down'}/>
                    </Header4WithLeftBorder>

                  </CardHeader>

                  <Collapse isOpen={this.state.secondCollapseOpen}>
                    <CardBody>

                      <MainText1>
                      Besides, we came up with two different ideas of VOI and VOI with Screens

                      <MainText1Bold> Only VOI </MainText1Bold>: The user can navigate and check different highlights only through
                      interacting with Castie. E.g, Castie will start the podcast by saying:
                      “we detected 5 highlights and 8 voice memos in this podcast, do you
                      want to listen to any of it?“.


                       <MainText1Bold>VOI with screens </MainText1Bold>: Commands will look like:
                        “Castie, play the first hightlights“. If user needs more detailed
                        information about highlights and demos, users need to check screen.

                      </MainText1>

                    </CardBody>
                  </Collapse>

                  {/*/!*Card Third*!/*/}
                  {/*<CardHeader onClick={this.toggleThird} style={{borderWidth:0,*/}
                  {/*backgroundColor:"transparent", padding: "0 0 0 0", marginTop: "24px"}} id="headingOne">*/}

                  {/*<Header4WithLeftBorder className="hvr-fade-accordion"> Competitive Analysis*/}
                  {/*<FontAwesomeIcon style={{position: "absolute", right: "20px"}}  size="lg"*/}
                  {/*icon={ this.state.thirdCollapseOpen? 'angle-up' : 'angle-down'}/>*/}
                  {/*</Header4WithLeftBorder>*/}

                  {/*</CardHeader>*/}

                  {/*<Collapse isOpen={this.state.thirdCollapseOpen}>*/}
                  {/*<CardBody >*/}
                  {/*<CompetitiveAnalysis/>*/}
                  {/*</CardBody>*/}
                  {/*</Collapse>*/}

                </Card>
              </div>
            </Col>
          </Row>

        </StyledContainer>
      )
    }

}

export default IdeationsCategories