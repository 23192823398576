import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import iaImage from '../../images/castie/information_architecture.jpeg'
import { StyledContainer } from '../layout'
import {
  Title,
  Acknowledgement,
  Brief,
  mainTextColor,
  accentColor,
  textFont,
  IntroLine,
  IntroSubHeader,
  Header4WithLeftBorder
} from './castie_style'
import { getColOf3, getSeperatorWithHeight } from '../text_style'
import { AnimatedText, ZoomableImageWithModal } from './castie_common_component'
import TrackVisibility from 'react-on-screen';


class InformationArchitecture extends React.Component{
  render(){

    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header4WithLeftBorder>
              Information Architecture
            </Header4WithLeftBorder>

          </Col>

        </Row>

        {getSeperatorWithHeight(20)}

        <Row>
          {getColOf3()}
          <Col md={6} className="d-flex justify-content-center">
            <ZoomableImageWithModal image={iaImage} header={"Information Architecture"}/>
          </Col>

        </Row>


      </StyledContainer>
    )

  }

}

export default InformationArchitecture