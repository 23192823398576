import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../stylesheet/style.css'
import { Layout } from '../components/layout'
import Intro from '../components/castie/intro_castie'
import { getSeperatorWithHeight } from '../components/text_style'
import Process from '../components/castie/process_castie'
import ProblemStatement from '../components/castie/problem_statement_castie'
import ResearchIntro from '../components/castie/research_intro_castie'
import ResearchActivities from '../components/castie/research_activities'
import UserPainPoints from '../components/castie/user_pain_points'
import IdeationIntro from '../components/castie/ideation_intro_castie'
import PrototypeIntro from '../components/castie/prototype_intro_castie'
import InformationArchitecture from '../components/castie/information_architecture'
import HighFidelityPrototype from '../components/castie/high_fidelity_prototype'
import VideoPrototyping from '../components/castie/video_prototype'
import IdeationsCategories from '../components/castie/ideations_categories'
import DesignDirectionInfoVis from '../components/info_vis/design_direction_infovis'
import DesignDirection from '../components/castie/design_direction'
import { SectionSeperator } from '../components/castie/castie_common_component'
import Footer from './footer'
import Scrollspy from 'react-scrollspy'
import SEO from '../components/seo'
import { Row } from 'reactstrap'

const Castie = () => (
  <Layout>
    <SEO title="Junjie Xu" keywords={[`gatsby`, `application`, `react`]} />
    {getSeperatorWithHeight(50)}
    <Scrollspy
      items={[
        'overview',
        'process',
        'problem_statement',
        'research',
        'ideation',
        'prototype',
      ]}
      style={{ position: 'fixed', paddingTop: '48px', listStyleType: 'none' }}
      currentClassName="is-current-infovis"
      scrolledPastClassName={'is-past-infovis'}
      offset={-30}
      className={'sidebar'}
    >
      <li className={'scollli'}>
        <a href="#overview">Overview</a>
      </li>
      <li className={'scollli'}>
        <a href="#process">Process</a>
      </li>
      <li className={'scollli'}>
        <a href="#problem_statement">Problem Statement</a>
      </li>
      <li className={'scollli'}>
        <a href="#research">Research</a>
      </li>
      <li className={'scollli'}>
        <a href="#ideation">Ideation</a>
      </li>
      <li className={'scollli'}>
        <a href="#prototype">Prototype</a>
      </li>
    </Scrollspy>

    <section id={'overview'}>
      <Intro />
    </section>

    <section id={'process'}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator />
      {getSeperatorWithHeight(100)}
      <Process />
    </section>

    <section id={'problem_statement'}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator />
      {getSeperatorWithHeight(100)}
      <ProblemStatement />
    </section>

    <section id={'research'}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator />
      {getSeperatorWithHeight(100)}
      <ResearchIntro />

      {getSeperatorWithHeight(30)}

      <ResearchActivities />
      {getSeperatorWithHeight(30)}

      <UserPainPoints />
    </section>

    <section id={'ideation'}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator />
      {getSeperatorWithHeight(100)}
      <IdeationIntro />

      {getSeperatorWithHeight(24)}
      <IdeationsCategories />

      {getSeperatorWithHeight(40)}
      <DesignDirection />
    </section>

    <section id={'prototype'}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator />
      {getSeperatorWithHeight(100)}

      <PrototypeIntro />

      {getSeperatorWithHeight(40)}
      <InformationArchitecture />

      {getSeperatorWithHeight(80)}
      <HighFidelityPrototype />

      {getSeperatorWithHeight(80)}
      <VideoPrototyping />
    </section>

    {getSeperatorWithHeight(200)}
    <Row className="d-flex justify-content-center">
      <a style={{ color: `#ADADAD` }} href="#">
        Back to top
      </a>
    </Row>
    {getSeperatorWithHeight(20)}
    <Footer />
  </Layout>
)

export default Castie
