import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import { Header2, getColOf3, getSeperatorWithHeight } from '../text_style'
import researchCroppedImage from '../../images/hci_foundation/Research-cropped.jpg'
import { StyledContainer } from '../layout'
import { AnimatedText } from './castie_common_component'
import { MainText1Bold, MainText1, Header2WithLeftBoarder, Header2SubtitleWithLeftBorder } from './castie_style'
import TrackVisibility from 'react-on-screen'


class PrototypeIntro extends React.Component {

  render(){
    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}> <Header2WithLeftBoarder> Prototype </Header2WithLeftBoarder> </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={6}> <Header2SubtitleWithLeftBorder> Create voice intearction rapidly to demonstrate our ideas </Header2SubtitleWithLeftBorder></Col>
        </Row>

        {getSeperatorWithHeight(24)}

        <Row>
          {getColOf3()}
          <Col md={6}>
            <TrackVisibility>
              <AnimatedText text={"To demonstrate our ideas and seek more feedback, we created artifacts with different level of details: "}/>
            </TrackVisibility>

            <MainText1>
              <ol style={{paddingLeft: `25px`}}>
                <li >
                  <MainText1Bold> Information Architecture </MainText1Bold> the structure of the information in voice interaction
                </li>

                <li >
                  <MainText1Bold> High Fidelity Prototype </MainText1Bold> interactive way to show our design
                </li>

                <li >
                  <MainText1Bold> Video Prototype </MainText1Bold> a demo to introduce how the idea can be used in real life
                </li>
              </ol>

            </MainText1>
          </Col>
        </Row>


      </StyledContainer>

    )
  }
}

export default PrototypeIntro;