import React from 'react'

import {MainText1, MainText1Bold, HoverAnimatedMainText1, Header4WithLeftBorder, AccordianMainText1} from './castie_style'

import { getColOf3, getSeperatorWithHeight } from '../text_style'
import caImage from '../../images/castie/competitive_analysis_icons.png'

class CompetitiveAnalysis extends React.Component{

  render(){
    return(
    <div>
      <AccordianMainText1>
        We tested multiple popular podcast applications on iOS to see how people might use voice to interact it.
      </AccordianMainText1>
      {getSeperatorWithHeight(10)}

      <img src={caImage} className="img-fluid" alt="competitive analysis"/>
      {getSeperatorWithHeight(10)}

      <AccordianMainText1> Key Insights: </AccordianMainText1>
      <AccordianMainText1>
      <ul>
        <li style={{marginBottom: 0}}>
          Only some podcasts have internal voice control, e.g Podcast in iOS
        </li>
        <li style={{marginBottom: 0}}> Only one podcast allow for highlighting
          certain sentences through transcripts</li>
      </ul>
      </AccordianMainText1>
    </div>
    )
  }
}

export default CompetitiveAnalysis