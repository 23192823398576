import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import researchImage from '../../images/hci_foundation/Screen-show-off.png'
import { StyledContainer } from '../layout'
import {
  Title,
  Acknowledgement,
  Brief,
  mainTextColor,
  accentColor,
  textFont,
  IntroLine,
  IntroSubHeader
} from './castie_style'
import YouTube from "react-youtube"


class Intro extends React.Component{

  render(){
    return (
      <StyledContainer fluid={true}>

          <Row>
            <Col md={3}> </Col>
            <Col md={6}>
            <Title xs={6}> Castie </Title>
            </Col>
            <Col xs={3}/>
          </Row>

        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            <Acknowledgement md={6}> Collaborated with Yixin Duan </Acknowledgement>
          </Col>

          <Col md={3}/>
        </Row>

        <Row style={{paddingTop: 48, paddingBottom: 48}}>
          <Col md = {3}> </Col>
          <Col md={6}>
          <Brief > The voice assistant that helps users listening to podcasts take notes </Brief>
          </Col>
        </Row>

        <Row>
          <Col md={3}/>
          <Col md={3}>
            <IntroSubHeader style={{paddingBottom:12}}>My Role:</IntroSubHeader>

            <IntroLine>Voice interaction design</IntroLine>
            <IntroLine>Created screen wireframes</IntroLine>
            <IntroLine>Set up paper prototype </IntroLine>
            <IntroLine>Act to demo the prototype</IntroLine>
          </Col>

          <Col md={3}>
            <IntroSubHeader style={{paddingBottom:12}}> Time: </IntroSubHeader>

            <IntroLine> Oct. 2018 </IntroLine>
          </Col>
          <Col md={3}/>
        </Row>

        <Row style={{paddingTop:40}}>
          <Col md={3}/>
          <Col md={6} className="d-flex justify-content-center">
            <YouTube style={{width:`100%`}} videoId="WX8EYDJ5_oA" />
          </Col>
        </Row>

      </StyledContainer>

    )

  }
}

export default Intro