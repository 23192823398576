import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2,  getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
import designDirectionImage from '../../images/infovis/Design_directions.png'
import { StyledContainer } from '../layout'
import {Header3, MainText1} from './infovis_style'

class DesignDirectionInfoVis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Design Direction
            </Header3>

            {getSeperatorWithHeight(28)}
            <MainText1>
              Based on our feedback, we decided on the mindset of “Less is more“, by focusing on kidney
              transplantation only. Data has shown us that the kidney transplantation has the most serious
              problem with the longest waiting list and the longest waiting time for patients. Thus, we
              shift our focus on the kidney transplantation. Many previous research results still hold.
            </MainText1>

            {getSeperatorWithHeight(28)}
            <MainText1>
              We want our design to be:
            </MainText1>
          </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={6} style={{textAlign:`center`}}>
            <img style={{width:`100%`, height:`auto`}} src={designDirectionImage} alt={"design direction"} />
          </Col>
        </Row>
      </StyledContainer>
    )
  }
}

export default DesignDirectionInfoVis