import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import researchImage from '../../images/hci_foundation/Screen-show-off.png'
import { StyledContainer } from '../layout'
import {
  Title,
  Acknowledgement,
  Brief,
  mainTextColor,
  accentColor,
  textFont,
  IntroLine,
  IntroSubHeader,
  Header4WithLeftBorder
} from './castie_style'
import { getColOf3, getSeperatorWithHeight } from '../text_style'
import { AnimatedText } from './castie_common_component'
import TrackVisibility from 'react-on-screen';

class UserPainPoints extends React.Component{

  constructor(props){
    super(props)

  }

  render(){

    return(
    <StyledContainer fluid={true}>


      <Row>
        {getColOf3()}
        <Col md={6}>
        <TrackVisibility >
          <AnimatedText text={"After our research, we summarized our user pain points:"}/>
        </TrackVisibility>
        </Col>
      </Row>

      {getSeperatorWithHeight(10)}
      <Row>

        {getColOf3()}

        <Col>
          <Header4WithLeftBorder>
            User Pain Point
          </Header4WithLeftBorder>
        </Col>

      </Row>
      {getSeperatorWithHeight(24)}

      <Row>
        {getColOf3()}
        <Col md={6}>
        <ul>
          <li style={{marginBottom: `5px`}}>
            It’s hard for users to check and operate screens on the crowded bus or driving
          </li>

          <li style={{marginBottom: `5px`}}>
            No functionality of highlighting in different applications
          </li>

          <li style={{marginBottom: `5px`}}>
            How to use voice to indicate which sentences to highlight precisely is hard
          </li>
        </ul>
        </Col>
      </Row>

    </StyledContainer>
    )
  }

}

export default UserPainPoints