import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import highlightImage from '../../images/castie/highlight-2.png'
import recordingImage from '../../images/castie/Voice Record-2.jpg'
import transcriptImage from '../../images/castie/transcript.png'


import { StyledContainer } from '../layout'
import {
  Title,
  Acknowledgement,
  Brief,
  mainTextColor,
  accentColor,
  textFont,
  IntroLine,
  IntroSubHeader,
  Header4WithLeftBorder,
  MainText1, MainText1Bold,
} from './castie_style'
import { getColOf3, getSeperatorWithHeight } from '../text_style'
import { AnimatedText, ZoomableImageWithModal } from './castie_common_component'
import TrackVisibility from 'react-on-screen';
import { textFontWeight } from '../site_style'

export let VoiceCommand = styled.div`
  font-family: ${textFont};
  font-weight: ${textFontWeight.semibold};
  font-style: italic;
  font-size: 16px;
  color: ${mainTextColor};
  letter-spacing: -0.5px;
  line-height: 30px;
`

class HighFidelityPrototype extends React.Component {

  render(){

    return(

      <StyledContainer fluid={true}>

        {/*First Row*/}

        <Row>
          {getColOf3()}

          <Col md={6}>
            <Header4WithLeftBorder>
                High-fidelity Prototype
            </Header4WithLeftBorder>

            {getSeperatorWithHeight(24)}

            <MainText1>
              With XD, we created different screens to show how the voice commands works with Podcast application. We also created some screens interaction with no voice commands but are essential to the whole notetaking experience.
            </MainText1>


          </Col>

        </Row>

        {getSeperatorWithHeight(40)}

        <Row>
          {getColOf3()}
          <Col md={3}>

            <VoiceCommand>“Castie, highlight” </VoiceCommand>
            {getSeperatorWithHeight(16)}
            <MainText1>
              Highlighting is the first feature for customizing users’ own podcast experience. When the users say this command to Castie, Castie will record a piece of 15-second podcast content prior to this point. The 15-second highlight will later be indicated on the progress bar.
            </MainText1>

            {getSeperatorWithHeight(24)}

            <VoiceCommand>“Jump to the first highlight” </VoiceCommand>
            {getSeperatorWithHeight(16)}
            <MainText1>
              When the users listen to the podcast, they can say “Jump to the first highlight” to listen to the highlight they created in that podcast.
            </MainText1>

          </Col>

          <Col md={3}>
            <ZoomableImageWithModal image={highlightImage} header={"Highlight Screen"}/>

          </Col>
        </Row>


        {getSeperatorWithHeight(24)}

        {/*Second role*/}
        <Row>
          {getColOf3()}


          <Col md={3}>
            <ZoomableImageWithModal image={highlightImage} header={"Voice Memo Screen"}/>

          </Col>

          <Col md={3}>

            <VoiceCommand> “Castie, set new voice memo” </VoiceCommand>
            <VoiceCommand> “Please go ahead” </VoiceCommand>
            <VoiceCommand> {`<Any content they want to say>`} </VoiceCommand>

            {getSeperatorWithHeight(16)}
            <MainText1>
              Highlighting is the first feature for customizing users’ own podcast experience. When the users say this command to Castie, Castie will record a piece of 15-second podcast content prior to this point. The 15-second highlight will later be indicated on the progress bar.
            </MainText1>

            {getSeperatorWithHeight(24)}

          </Col>
        </Row>


        {/*Transcript interaction*/}

        <Row>
          {getColOf3()}
          <Col md={3}>

            <MainText1> <MainText1Bold> Transcript interaction </MainText1Bold> </MainText1>
            {getSeperatorWithHeight(16)}
            <MainText1>
              This screen shows the transcript for a podcast, assuming that it exists or gets automatically generated from the audio file. By changing color and adding icons, the transcript will show users’ highlights and voice memos. Those personalized notes and voice memos make the transcript unique to each user. They can review it later.
            </MainText1>

          </Col>

          <Col md={3}>
            <ZoomableImageWithModal image={transcriptImage} header={"Screen Transcript"}/>

          </Col>
        </Row>



      </StyledContainer>


    )
  }
}
export default HighFidelityPrototype