import {Row, Col, Container} from 'reactstrap'
import styled from 'styled-components'
import React from 'react'
import { getColOf3, getSeperatorWithHeight } from '../text_style'
import {MainText1, Header2WithLeftBoarder, Header2SubtitleWithLeftBorder} from './castie_style'
import { StyledContainer } from '../layout'
import TrackVisibility from "react-on-screen"
import { MainText1Bold } from './castie_style'
import { AnimatedText } from './castie_common_component'

class IdeationIntro extends React.Component{

    render(){

    return(

        <StyledContainer fluid={true}>
            <Row>
              {getColOf3()}
              <Col md={6} > <Header2WithLeftBoarder> Ideation </Header2WithLeftBoarder> </Col>
            </Row>

            <Row>
              {getColOf3()}
              <Col md={6}> <Header2SubtitleWithLeftBorder> Explore different possible
                solutions for voice interaction </Header2SubtitleWithLeftBorder></Col>
            </Row>

            {getSeperatorWithHeight(28)}

        </StyledContainer>

    )
    }
}

export default IdeationIntro;