import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import indicator from '../../images/castie/Indicator_castie1.svg'
import indicator2 from '../../images/castie/Indicator_castie2.svg'
import indicator3 from '../../images/castie/Indicator_castie3.svg'
import {SectionIntroHeader} from './castie_common_component'
import {getSeperatorWithHeight} from '../text_style'
import { displayFont, mainTextColor, accentColor, textFont, textFontWeight } from './castie_style'
import { StyledContainer } from '../layout'


let StyledLine = styled.div`

  font-family: ${textFont};
  font-weight: ${textFontWeight.regular};
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  text-align: left;
`

let StepHeader = styled.div`

  font-family: ${displayFont};
  font-size: 24px;
  line-height: 34px;
  color: ${mainTextColor};
  text-align: left;
`


let contentList1 = ["Literature Review", "Competitive analysis"]

let contentList2 = ["Wireframing", "Brainstorm"]

let contentList3 = [
  "Information architecture",
  "Video demo",
  "High-fidelity Prototype",
]


let contentList4 = [
  "Heuristic evaluation",
  "User usability testing",
]


class Step extends React.Component{

  render(){
    return(
      <div>
        <StepHeader> {this.props.header} </StepHeader>

        <img src={this.props.indicator} style={{height:"6px", marginBottom:`5px`}} className="img-fluid" alt="Responsive image"/>

        {this.props.contentList.map(d => {
            return(
          <StyledLine> {d} </StyledLine>)
        })}
      </div>
    )
  }
}

class Process extends React.Component{



  render() {
    let data = {
        header: "Process",
        subtitle: "Follow the design thinking and process"
    }
   return (

     <StyledContainer fluid={true}>

       <Row>
         <Col md={3}> </Col>
         <Col md={6}> <SectionIntroHeader header={data.header} subtitle={data.subtitle}  /> </Col>
       </Row>

       {getSeperatorWithHeight (30)}

       <Row>
         <Col md={3}> </Col>
         <Col md={2}> <Step header={"Research"} contentList={contentList1} indicator={indicator} /> </Col>
         <Col md={2}> <Step header={"Ideation"} contentList={contentList2} indicator={indicator2} /> </Col>
         <Col md={2}> <Step header={"Prototype"} contentList={contentList3} indicator={indicator3} /> </Col>
       </Row>
     </StyledContainer>
   )
  }
}




export default Process